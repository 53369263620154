export default {
  data () {
    return {
      include:['Login','BaseInfo','BusinessOwner','OfficeWorker','Student','Unemployed','Withdrawal']
    }
  },
  // 'SetPassWord','OtpLogin','PassLogin','Register',

  beforeRouteLeave (to, from, next) {
    // console.log(to, from, next);
    if (to.name == "PrivacyAgreement"){
      console.log(111111);
      this.$store.state.settings.include=['SetPassWord','OtpLogin','PassLogin','Register','Login','BaseInfo','BusinessOwner','OfficeWorker','Student','Unemployed','Withdrawal']
    }else{
      console.log(2222);
      this.$store.state.settings.include=['Login','BaseInfo','BusinessOwner','OfficeWorker','Student','Unemployed','Withdrawal']
    }
    setTimeout(()=>{
      next();
    },10)
    
  },

}