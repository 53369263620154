<template>
  <div>
    <base-template :show-head="false">
      <LoginTop />
      <div class="login-phone-info">
        <p class="input-title">{{ $t("login.phoneNumber") }}</p>
        <van-field
          v-model="dataForm.mobile"
          center
          class="phone-input"
          :maxlength="maxlength"
          type="digit"
          label="+63"
          :placeholder="$t('baseInfo.telPhoneLogin')"
          @blur="blurFun('mobile_phone_number_duration')"
          @focus="focusFun('mobile_phone_number_num')"
        />
        <submit-btn
          ref="submit-btn"
          :sub-text="$t('login.nextStep')"
          :submit-flag="submitFlag"
          @submit="submit"
        />

        <div class="lg-box">
          <span :class="{ 'is-active': !isEn }" @click="isEn = false"
            >Tagalog</span
          >
          <span :class="{ 'is-active': isEn }" @click="isEn = true"
            >English</span
          >
        </div>
      </div>
    </base-template>
  </div>
</template>

<script>
import { getnewtime, getinterval } from "@/utils";
import { checkMobile, registPoint } from "@/api/axios/index";
import LoginTop from "./components/LoginTop";
import * as validator from "@/utils/validate";
import i18n from "@/i18n";
import toRouter from "@/mixins/toRouter";
export default {
  name: "Login",
  components: { LoginTop },
  mixins: [toRouter],
  data() {
    return {
      isEn: i18n.locale === "en",
      dataForm: {
        mobile: localStorage.getItem("mobile") || "",
      },
      start: 0,
      bankinfomaidian: {
        mobile_phone_number_duration: 0, //手机号输入时长
        mobile_phone_number_updatecount: 0, //手机号修改次数
        mobile_phone_number_num: 0, //手机号点击次数
        next_num: 0, //下一步点击次数
      },
    };
  },
  computed: {
    submitFlag() {
      if (this.dataForm.mobile.indexOf("09") === 0) {
        return this.dataForm.mobile.length >= 11;
      } else if (this.dataForm.mobile.indexOf("63") === 0) {
        return this.dataForm.mobile.length >= 12;
      } else {
        return this.dataForm.mobile.length >= 10;
      }
    },
    maxlength() {
      if (this.dataForm.mobile.indexOf("09") === 0) {
        return 11;
      } else if (this.dataForm.mobile.indexOf("63") === 0) {
        return 12;
      } else {
        return 10;
      }
    },
    andirodData: {
      get() {
        return this.$store.state.settings.andirodData;
      },
      set(val) {
        console.log(val)
        this.$store.dispatch("settings/changeSetting", {
          key: "andirodData",
          value: val,
        });
      },
    },
  },
  watch: {
    isEn: {
      handler(val) {
        i18n.locale = val ? "en" : "ph";
        const lgObj = JSON.stringify({
          key: val ? "en" : "ph",
          val: val ? "en" : "ph",
        });
        localStorage.setItem("isEn", lgObj);
        this.andirodData.Icitundu = val ? "en" : "ph";
        this.$H5Object["changeLG" + i18n.locale]();
      },
      immediate: false,
    },
    "dataForm.mobile": {
      handler() {
        console.log("dataForm");
        this.updatecountfun("mobile", "mobile_phone_number_updatecount");
      },
    },
  },

  created() {
    console.log(i18n.locale);
    this.$H5Object.changeStatusBarBgColor("#FFFFFF");
    this.$H5Object.changeStatusIsDarkFont(true);
    this.$H5Object.isShowBottomTabinter(false);
  },
  mounted() {},
  methods: {
    // 获取焦点
    focusFun(nametwo) {
      this.start = getnewtime(); // 初始化时间
      this.bankinfomaidian[nametwo]++;
    },

    // 失去焦点 - 计算时长
    blurFun(name) {
      //  计算时长
      this.bankinfomaidian[name] += getinterval(getnewtime(), this.start);
      console.log(getinterval(getnewtime(), this.start));
      console.log(this.bankinfomaidian, name);
    },
    // 次数
    updatecountfun(name, nametwo) {
      // 初始化不要走
      if (!this.dataForm[name]) {
        this.bankinfomaidian[nametwo]++;
      }
    },

    checkMobileFirst() {
      if (this.dataForm.mobile.indexOf("63") === 0) {
        this.dataForm.mobile = this.dataForm.mobile.replace(/^63/g, "0");
      }
    },
    submit() {
      this.checkMobile();
    },
    async checkMobile() {
      if (!validator.validWhatsapp(this.dataForm.mobile)) {
        console.log(1111);
        return;
      }
      this.bankinfomaidian.next_num++;
      // "check_status" : 1 //1.未注册  2.已注册
      let routerPath = "/passLogin";
      let mobile = this.dataForm.mobile;
      if (this.dataForm.mobile.indexOf("63") === 0) {
        mobile = mobile.replace(/^63/g, "0");
      }
      const { data } = (await checkMobile({ phone: mobile })) || {};
      if (!data) return;
      if (data?.register + "" === "1") routerPath = "/register";
      localStorage.clear("all");
      localStorage.setItem("mobile", this.dataForm.mobile);
      this.$router.push({ path: routerPath, query: this.dataForm });
      console.log(JSON.stringify(this.bankinfomaidian));
      // await registPoint(this.bankinfomaidian)
    },
  },
};
</script>
<style lang="scss" scoped>
:deep(.is-content) {
  padding-left: 64px;
  padding-right: 64px;
}

.login-phone-info {
  position: relative;
  padding-top: 128px;

  .input-title {
    font-size: 32px;
    font-family: Roboto-Regular, Roboto;
    font-weight: 400;
    color: #1a1a1a;
    line-height: 48px;
    padding-bottom: 32px;
  }
}

:deep(.btn-box) {
  padding-top: 0;
  margin-bottom: 0;
}

:deep(.phone-input) {
  width: 100%;
  padding: 0;
  margin-bottom: 128px;
  height: 128px;
  background: #f7f7f7;
  border-radius: 32px;

  input::-webkit-input-placeholder {
    white-space: inherit;
    position: relative;
    top: -10px;
  }
}

:deep(.btn-box .submit-btn) {
  margin-bottom: 80px;
}

:deep(.phone-input .van-field__label) {
  position: relative;
  padding: 34px;
  width: auto;
  padding-left: 48px;
  padding-right: 40px;
  font-size: 28px;
  font-family: Roboto-Regular, Roboto;
  font-weight: 400;
  color: #000000;
  margin-right: 0;
}

:deep(.phone-input .van-field__control) {
  line-height: 40px;
  font-size: 28px;
  font-family: Roboto-Regular, Roboto;
  font-weight: 400;
  color: #000000;
}

:deep(.login-top) {
  padding-top: 130px;
}

.lg-box {
  display: flex;
  justify-content: space-between;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    width: 2px;
    height: 40px;
    background: #cccccc;
    left: 50%;
    margin-left: -1px;
  }

  span {
    flex: 1;
    font-size: 28px;
    font-family: Roboto-Regular, Roboto;
    font-weight: 400;
    color: #cccccc;
    line-height: 32px;
    text-align: right;
    margin-right: 60px;

    &:last-child {
      margin-left: 60px;
      text-align: left;
      margin-right: 0;
    }
  }

  .is-active {
    color: #1c1d21;
  }
}
</style>

