<template>
  <div class="login-top">
    <Logo class="logo" />
    <p class="app-name">Cash<span>Agad</span></p>
    <p class="quick-login">Fast and reliable, bringing infinite convenience to your life</p>
    <!-- <div class="login-bg" />
    <p v-if="hasRemark" class="remark">{{ $t('login.inputPhone') }}</p> -->
  </div>
</template>

<script>
import Logo from '@/components/Logo.vue'
export default {
  name: 'LoginTop',
  components: { Logo },
  props: {
    hasRemark: {
      type: Boolean,
      default: false
    }

  },
  data() {
    return {

    }
  }
}
</script>
<style lang="scss" scoped>
:deep(.logo){
  display: flex;
  justify-content: left;
  .logo-box{
    width: 128px;
    height: 128px;
  }
  .remark{
    display: none;
  }
}

.app-name{
  text-align: left;
  font-size: 72px;
  font-family: Roboto-Bold, Roboto;
  font-weight: bold;
  color: #1C1D21;
  margin-top: 32px;
  margin-bottom: 18px;
  span{
    color: #005FE9;
    position: relative;
    &::after{
      content: '';
      position: absolute;
      width: 30px;
      // height: 50px;
      right: -44px;
      bottom: 0;
      top: 14px;
      background: url('~@/assets/images/login/t-icon.png') no-repeat center center;
      background-size: 100%;
    }
  }
}
.quick-login{
  font-size: 26px;
  font-family: Roboto-Regular, Roboto;
  font-weight: 400;
  color: #999999;
  line-height: 40px;
  position: relative;
  padding-right: 128px;
  padding-bottom: 32px;
  &::before{
    content: '';
    position: absolute;
    width: 64px;
    height: 12px;
    left: 0;
    bottom: 0;
    background: #005FE9;
  }
  &::after{
    content: '';
    position: absolute;
    width: 40px;
    height: 48px;
    top: 14px;
    right: 68px;
    background: url('~@/assets/images/public/fire-icon.png') no-repeat center center;
    background-size: 100%;
  }
}

</style>
